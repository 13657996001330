import { render, staticRenderFns } from "./Go.vue?vue&type=template&id=256d541c&scoped=true"
import script from "./Go.vue?vue&type=script&lang=js"
export * from "./Go.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "256d541c",
  null
  
)

export default component.exports